body {
  font-family: monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

* {
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 8px;
  background-color: #445435;
}

.ReactTags__selected {
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
}

.history::-webkit-scrollbar {
  width: 1em;
}

.history::-webkit-scrollbar-track {
  background-color: transparent;
}

.history::-webkit-scrollbar-thumb {
  border: 1px solid;
  border-top-color: #667359;
  border-left-color: #667359;
  background-color: #445435;
  border-bottom-color: #1f2719;
  border-right-color: #1f2719;
}

.history {
  color: rgba(255, 255, 255, 0.75);
  padding: 4px;
  border: 1px solid;
  border-bottom-color: #687360;
  border-right-color: #687360;
  border-top-color: #1f2719;
  border-left-color: #1f2719;
  background-color: #303f25;
  margin-bottom: 8px;
  flex-grow: 1;
  overflow: auto;
}

.ReactTags__tags {
  border: 1px solid;
  border-bottom-color: #687360;
  border-right-color: #687360;
  border-top-color: #1f2719;
  border-left-color: #1f2719;
  background-color: #303f25;
}

.ReactTags__tag {
  margin: 1px;
  padding: 2px;
  border: 1px solid;
  border-top-color: #667359;
  border-left-color: #667359;
  background-color: #445435;
  border-bottom-color: #1f2719;
  border-right-color: #1f2719;
  color: rgba(255, 255, 255, 0.75);
}

.ReactTags__remove {
  display: none;
}

.ReactTags__tagInput {
  flex-grow: 1;
  display: flex;
}

.ReactTags__tagInputField {
  border: none;
  padding: 4px;
  flex-grow: 1;
  font-family: inherit;
  font-size: inherit;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.75);
}

.ReactTags__tagInputField::-moz-placeholder,
.ReactTags__tagInputField::-moz-placeholder,
.ReactTags__tagInputField::-webkit-input-placeholder,
.ReactTags__tagInputField:-ms-input-placeholder,
.ReactTags__tagInputField::-ms-input-placeholder {
  color: white;
}

.ReactTags__tagInputField::placeholder,
.ReactTags__tagInputField::-moz-placeholder,
.ReactTags__tagInputField::-webkit-input-placeholder,
.ReactTags__tagInputField:-ms-input-placeholder,
.ReactTags__tagInputField::-ms-input-placeholder {
  color: white;
}

.ReactTags__tagInputField:focus {
  outline: none;
}

.ReactTags__suggestions {
  position: absolute;
  bottom: calc(100% + 1px);
  left: -1px;
  max-height: 80vh;
  overflow: auto;
}

.ReactTags__suggestions::-webkit-scrollbar {
  width: 12px;
}

.ReactTags__suggestions::-webkit-scrollbar-track {
  border-top-color: #1f2719;
  border-left-color: #1f2719;
  background-color: #303f25;
  border-bottom-color: #687360;
  border-right-color: #687360;
}

.ReactTags__suggestions::-webkit-scrollbar-thumb {
  border: 1px solid;
  border-top-color: #667359;
  border-left-color: #667359;
  background-color: #445435;
  border-bottom-color: #1f2719;
  border-right-color: #1f2719;
}

.ReactTags__suggestions > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid;

  border-top-color: #687360;
  border-left-color: #687360;
  border-bottom-color: #1f2719;
  border-right-color: #1f2719;
  background-color: #303f25;
  color: rgba(255, 255, 255, 0.75);
}

.ReactTags__suggestions > ul > li {
  padding: 4px;
}

.ReactTags__activeSuggestion {
  color: white;
  background-color: #445435;
}

.play-sentence {
  margin-top: 8px;

  border-top-color: #667359;
  border-left-color: #667359;
  background-color: #445435;
  border-bottom-color: #1f2719;
  border-right-color: #1f2719;
  color: rgba(255, 255, 255, 0.75);

  padding: 4px;
  font-size: inherit;
  font-family: inherit;
}

mark {
  background-color: #667359;
}

.title {
  color: white;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

a {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

a:visited {
  color: white;
}

a:hover {
  text-decoration: underline;
}

.history-item {
  display: block;
}
